import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01dfb7c3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "warp_container" }
const _hoisted_2 = { class: "masker" }
const _hoisted_3 = { class: "code" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "right" }


import { useQRCode } from "@vueuse/integrations/useQRCode";

export default {
  props: ["id"],
  emits: ["loginStore"],
  setup(__props, { emit: emits }) {

const props = __props



const qrCode = useQRCode(
  `http://gangxinbao.cn/#/share/company?prise_id=${props.id}`
);
/* const props = DefaultProps({
  code: {
    type: String,
    default: () => "@/assets/luowenwang/code.png",
  },
}); */

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _unref(qrCode),
          alt: ""
        }, null, 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "login-store",
        onClick: _cache[0] || (_cache[0] = $event => (emits('loginStore', __props.id)))
      }, " 进入店铺>> ")
    ])
  ]))
}
}

}